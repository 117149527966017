import React, { Component } from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../layout"
import SEO from "../components/seo"

import "../assets/styles/fonts.css"
import "../assets/styles/components/page-modules.css"

class Home extends Component {
  
  render() {
    const data = this.props.data;

    return (
      <DefaultLayout className="homepage">
        <SEO title={data.allWordpressPage.edges[0].node.yoast_title} />
        {
          data.allWordpressPage.edges.map(
          ({ node }) => node.slug === 'home' ? (
          <div key={node.slug} className="entry-content"><div className="holdingpage" dangerouslySetInnerHTML={{ __html: node.content }} /></div>
          ) : ( 
          <div key={node.slug} className="entry-content"><div className="wrapper" dangerouslySetInnerHTML={{ __html: node.content }} /></div>
          ))
        }
      </DefaultLayout>
    )
  }
}
export default Home

export const query = graphql`
  {
    allWordpressPage {
      edges {
        node {
          title
          content
          slug
          yoast_title
        }
      }
    }
    wordpressSiteMetadata {
      name
      description
    }
  }`